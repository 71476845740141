.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.ql-video {
  max-width: 560px !important;
  width: 100%;
  height: 315px;
  margin: 0 auto;
}

.ql-button {
  padding: 16px 24px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
}
.ql-tooltip {
  left: 10px !important;
}
