$primary: #108043;

#nprogress {
  .bar {
    background: $primary;
  }
  .peg {
    box-shadow: 0 0 10px $primary, 0 0 5px $primary;
  }

  .spinner-icon {
    border-top-color: $primary;
    border-left-color: $primary;
  }
}
